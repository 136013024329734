const id = "AIzaSyBg0xk8reNEDMogrjpF9COaq2kGRAecHBI";

export const pageview = (url: URL) => {
  if (!id || !window.gtag) return;
  window?.gtag("config", id, { page_path: url });
};

export const event = (action: string, params?: Record<string, unknown>) => {
  if (!window.gtag) return;
  window.gtag("event", action, params);
};
