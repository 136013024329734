import { createGlobalStyle } from "styled-components";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-image-gallery/styles/css/image-gallery.css";

export const GlobalStyles = createGlobalStyle`
html,body{
	font-family: 'Work Sans', Arial;
	font-size: 16px;
	margin: 0;
	padding: 0;
    p {
        strong {
            font-weight: 500;
        }
    }
    h2 {
        font-weight: 500;
    }
}

* {
	box-sizing: border-box;
}

@font-face {
	font-family: 'feelinghome';
	/* src:url('/fonts/feelinghome.eot?-v0gf06'); */
	/* src:url('/fonts/feelinghome.eot?#iefix-v0gf06') format('embedded-opentype'), */
	src:url('/fonts/feelinghome_v2.woff2?-v0gf06') format('woff2'),
	    url('/fonts/feelinghome_v2.woff?-v0gf06') format('woff'),
		url('/fonts/feelinghome_v2.ttf?-v0gf06') format('truetype'),
		url('/fonts/feelinghome_v2.svg?-v0gf06#feelinghome') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
    font-family: 'DomaineDispNar';
    src: url('/fonts/DomaineDispNar/DomaineDispNar-Bold.eot');
    src: url('/fonts/DomaineDispNar/DomaineDispNar-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DomaineDispNar/DomaineDispNar-Bold.woff2') format('woff2'),
        url('/fonts/DomaineDispNar/DomaineDispNar-Bold.woff') format('woff'),
        url('/fonts/DomaineDispNar/DomaineDispNar-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DomaineDispNar';
    src: url('/fonts/DomaineDispNar/DomaineDispNarSemibold-Italic.eot');
    src: url('/fonts/DomaineDispNar/DomaineDispNarSemibold-Italic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DomaineDispNar/DomaineDispNarSemibold-Italic.woff2') format('woff2'),
        url('/fonts/DomaineDispNar/DomaineDispNarSemibold-Italic.woff') format('woff'),
        url('/fonts/DomaineDispNar/DomaineDispNarSemibold-Italic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'DomaineDispNar';
    src: url('/fonts/DomaineDispNar/DomaineDispNar-BoldItalic.eot');
    src: url('/fonts/DomaineDispNar/DomaineDispNar-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DomaineDispNar/DomaineDispNar-BoldItalic.woff2') format('woff2'),
        url('/fonts/DomaineDispNar/DomaineDispNar-BoldItalic.woff') format('woff'),
        url('/fonts/DomaineDispNar/DomaineDispNar-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'DomaineDispNar';
    src: url('/fonts/DomaineDispNar/DomaineDispNarSemibold.eot');
    src: url('/fonts/DomaineDispNar/DomaineDispNarSemibold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DomaineDispNar/DomaineDispNarSemibold.woff2') format('woff2'),
        url('/fonts/DomaineDispNar/DomaineDispNarSemibold.woff') format('woff'),
        url('/fonts/DomaineDispNar/DomaineDispNarSemibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'DomaineDispNar';
    src: url('/fonts/DomaineDispNar/DomaineDispNar-RegularItalic.eot');
    src: url('/fonts/DomaineDispNar/DomaineDispNar-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DomaineDispNar/DomaineDispNar-RegularItalic.woff2') format('woff2'),
        url('/fonts/DomaineDispNar/DomaineDispNar-RegularItalic.woff') format('woff'),
        url('/fonts/DomaineDispNar/DomaineDispNar-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'DomaineDispNar';
    src: url('/fonts/DomaineDispNar/DomaineDispNarExtrabold.eot');
    src: url('/fonts/DomaineDispNar/DomaineDispNarExtrabold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DomaineDispNar/DomaineDispNarExtrabold.woff2') format('woff2'),
        url('/fonts/DomaineDispNar/DomaineDispNarExtrabold.woff') format('woff'),
        url('/fonts/DomaineDispNar/DomaineDispNarExtrabold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DomaineDispNar';
    src: url('/fonts/DomaineDispNar/DomaineDispNar-Regular.eot');
    src: url('/fonts/DomaineDispNar/DomaineDispNar-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DomaineDispNar/DomaineDispNar-Regular.woff2') format('woff2'),
        url('/fonts/DomaineDispNar/DomaineDispNar-Regular.woff') format('woff'),
        url('/fonts/DomaineDispNar/DomaineDispNar-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DomaineDispNar';
    src: url('/fonts/DomaineDispNar/DomaineDispNarBlack-Italic.eot');
    src: url('/fonts/DomaineDispNar/DomaineDispNarBlack-Italic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DomaineDispNar/DomaineDispNarBlack-Italic.woff2') format('woff2'),
        url('/fonts/DomaineDispNar/DomaineDispNarBlack-Italic.woff') format('woff'),
        url('/fonts/DomaineDispNar/DomaineDispNarBlack-Italic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'DomaineDispNar';
    src: url('/fonts/DomaineDispNar/DomaineDispNarMedium-Italic.eot');
    src: url('/fonts/DomaineDispNar/DomaineDispNarMedium-Italic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DomaineDispNar/DomaineDispNarMedium-Italic.woff2') format('woff2'),
        url('/fonts/DomaineDispNar/DomaineDispNarMedium-Italic.woff') format('woff'),
        url('/fonts/DomaineDispNar/DomaineDispNarMedium-Italic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'DomaineDispNar';
    src: url('/fonts/DomaineDispNar/DomaineDispNarExtrabold-Italic.eot');
    src: url('/fonts/DomaineDispNar/DomaineDispNarExtrabold-Italic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DomaineDispNar/DomaineDispNarExtrabold-Italic.woff2') format('woff2'),
        url('/fonts/DomaineDispNar/DomaineDispNarExtrabold-Italic.woff') format('woff'),
        url('/fonts/DomaineDispNar/DomaineDispNarExtrabold-Italic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'DomaineDispNar';
    src: url('/fonts/DomaineDispNar/DomaineDispNarBlack.eot');
    src: url('/fonts/DomaineDispNar/DomaineDispNarBlack.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DomaineDispNar/DomaineDispNarBlack.woff2') format('woff2'),
        url('/fonts/DomaineDispNar/DomaineDispNarBlack.woff') format('woff'),
        url('/fonts/DomaineDispNar/DomaineDispNarBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'DomaineDispNar';
    src: url('/fonts/DomaineDispNar/DomaineDispNarMedium.eot');
    src: url('/fonts/DomaineDispNar/DomaineDispNarMedium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DomaineDispNar/DomaineDispNarMedium.woff2') format('woff2'),
        url('/fonts/DomaineDispNar/DomaineDispNarMedium.woff') format('woff'),
        url('/fonts/DomaineDispNar/DomaineDispNarMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

.react-datepicker-popper{
	z-index: 2000;
}

.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay {
	&.rdrDayDisabled {
		background-color: #bebebe;
		border: none;
		border-radius: 0;
		span {
			color: #fff;
		}
	}
}
`;
