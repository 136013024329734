import React, { useEffect } from "react";
import Head from "next/head";
import { FeelingHomeProviders } from "@templates/providers";
import App, {
  AppContext,
  AppInitialProps,
  AppProps,
  NextWebVitalsMetric,
} from "next/app";
import { appWithTranslation, useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import * as ga from "@fhlib/ga";
import { ClientConfig } from "@templates/config";

type AppOwnProps = { config: ClientConfig };

function MyApp({ Component, pageProps, config }: AppProps & AppOwnProps) {
  const { t } = useTranslation();
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: URL) => ga.pageview(url);
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <title>Feeling Home</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta name="description" content={t("meta-description")} />
        <meta property="og:site_name" content="Feeling Home" />
        <meta property="og:title" content="Feeling home" />
        <meta property="og:description" content={t("meta-description")} />
        <meta property="og:image" content="/images/logo_header.png" />
        <meta property="og:type" content="website" />
      </Head>
      <FeelingHomeProviders config={config}>
        <Component {...pageProps} />
      </FeelingHomeProviders>
    </>
  );
}

MyApp.getInitialProps = async (
  context: AppContext
): Promise<AppOwnProps & AppInitialProps> => {
  const ctx = await App.getInitialProps(context);

  const config: ClientConfig = {
    backUrl: process.env.PUBLIC_DIRECTUS_URL ?? "https://back.feelinghome.es",
  };

  return {
    ...ctx,
    config,
  };
};

export function reportWebVitals(metric: NextWebVitalsMetric) {
  const { id, name, label, value } = metric;
  if (typeof window === "undefined" || !window.gtag) return;

  window.gtag("event", name, {
    event_category: label === "web-vital" ? "Web Vitals" : "Feeling Home",
    value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    event_label: id, // id unique to current page load
    non_interaction: true, // avoids affecting bounce rate.
  });
}

export default appWithTranslation(MyApp);
