import { FC, PropsWithChildren } from "react";
import { GlobalStyles } from "./global-styles";
import { ClientConfig, FHConfigContext } from "./config";

export const FeelingHomeProviders: FC<
  PropsWithChildren<{ config: ClientConfig }>
> = ({ children, config }) => {
  return (
    <FHConfigContext.Provider value={config}>
      <GlobalStyles />
      {children}
    </FHConfigContext.Provider>
  );
};
